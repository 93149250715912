html,
body {
  position: relative;
  height: 100%;
  padding: 10 !important;
  margin: 10 !important;
  background-color: #eaeaea;
  overflow-x: hidden !important;
  z-index: 1;
}

h1 {
  margin: auto;
  padding: 10px !important;
}

.container {
  overflow: hidden;
}

.item {
  position: absolute;
  width: 100px;
  height: 100px;
  color: white;
  text-align: center;
}

.item:nth-child(6n + 1) {
  background: #f55;
  height: 200px;
}

.item:nth-child(6n + 2) {
  background: #7e7;
  height: 300px;
}

.item:nth-child(6n + 3) {
  background: #66e;
  height: 200px;
}

.item:nth-child(6n + 4) {
  background: #4af;
  height: 100px;
}

.item:nth-child(6n + 5) {
  background: #ed5;
  height: 150px;
}

.item:nth-child(6n + 6) {
  background: #d5e;
  height: 130px;
}

.result {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 14px;
}

.button {
  position: relative;
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background: white;
  border: 1px solid #ccc;
  appearance: none;
  font-weight: bold;
  width: 150px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
}

.title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  .title,
  .result {
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  .title,
  .result {
    color: black;
  }
}

.controlPanel {
  position: absolute;
  z-index: 2;
  transform: translate(10px, 10px);
  max-width: calc(100% - 20px);
}
.controlBtn {
  padding: 6px 12px;
  background: white;
  border: 1px solid grey;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.controlBtn:focus {
  filter: brightness(90%);
}

.controlBtn:hover {
  filter: brightness(120%);
}

.controlBtn:active {
  opacity: 0.9;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 2vh;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #d8e2dc;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #48cae4;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #d8e2dc;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #d8e2dc;
  /*padding: 2.5em 1.5em 0;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #d8e2dc;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  width: 100vw !important;
}

.slide-right {
  width: 100%;
  overflow: hidden;

  max-width: 50%;
}

.slide-right p {
  animation: 2s slide-right forwards;
  transform: translateX(-100%);
}

@keyframes slide-right {
  to {
    transform: translateX(0);
  }
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.image {
  position: relative;
  width: 100%;
  font-size: 0;
  /* border-radius: 15; */
  overflow: hidden;
}

.image img {
  width: 100%;
  flex: 1;
}

.image-full {
  position: relative;
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.07);
  flex-direction: row;
  font-size: 0;
  /* border-radius: 15; */
  overflow: hidden;
  transition: opacity 0.2s, visibility 0.2s;
  justify-content: center;
  align-items: center;
}

.image-mobile {
  width: 90%;
  margin: 10px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  font-size: large;
  visibility: hidden;
  margin-left: 10px;
  opacity: 0;
  /* transition effect. not necessary */
  transition: opacity 0.2s, visibility 0.2s;
}

.image-full:hover img {
  opacity: 0.5;
}

.image-full:hover .image-description,
.image-full:hover .image-button {
  visibility: visible;
  opacity: 1;
}

.image-full img {
  width: 100%;
  flex: 1;
}

.image-mobile img {
  min-height: 200px;
}

.image-button {
  text-align: center;
  /* top: 40%;
  left: 40%; */
  z-index: 5;
  width: 60%;
  height: 80px;
  position: absolute;
  font-size: large;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.image-button button {
  cursor: pointer;
  font-size: large;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  color: rgb(208, 237, 250);
  height: 100%;
  border-radius: 5px;
  border: 2px solid #66e;
  background-color: rgba(68, 170, 255, 0.2);
}

.image-button button:hover,
.image-button button:focus {
  background-color: rgba(68, 170, 255, 0.4);
  color: #fff;
  box-shadow: 0 0.5em 0.5em -0.4em #4af;
  transform: translateY(-0.25em);
  transition: 0.25s;
}

.header {
  height: 8vh;
  width: 100%;
  color: #fff;
  background-color: #80727b;
  box-shadow: 1px 4px 14px 0px rgba(0, 0, 0, 0.52);
  -webkit-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 1px 1px 14px 0px rgba(0, 0, 0, 0.52);
  /* border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}

.header > h1 {
  font-family: "Montserrat Alternates", sans-serif;
  margin: unset;
}

.header > img {
  width: 50px;
  /* margin-left: 10px;
  margin-right: 10px; */
}

#page-wrap {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#outer-container {
  width: 100vw;
}

.header > div > a.header-title {
  display: block;
  color: #fff;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  margin: auto;
  padding: 10px !important;
  text-decoration: none;
}

#h-menu > a {
  transition: 0.15s;
  color: #fff;
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  text-decoration: none;
}

.bm-item-list > #h-menu > a {
  transition: 0.15s;
  color: #80727b;
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500;
  text-decoration: none;
}

#h-menu > a:hover {
  cursor: pointer;
  color: #48cae4;
}

.bm-item-list > #h-menu > a:hover {
  cursor: pointer;
  color: #48cae4;
}

#close-button {
  transition: 0.15s;
}

#close-button:hover {
  color: #4af;
}

div.image-gallery-content.fullscreen span.image-gallery-description {
  display: none !important;
}
